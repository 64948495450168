import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'

export default function History({ history }) {

    return (

        <div className="mx-auto max-w-7xl px-2 py-6 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                    <div className="border-b border-gray-200 px-4 py-4 sm:px-6">
                        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Search History
                        </h2>
                    </div>
                    <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
                        <div className="mt-0 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full max-h-80 py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="overflow-hidden  sm:rounded-lg">
                                        <table className="min-w-full divide-y ">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Bus No
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Result
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Check
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {history.map((data) => (
                                                    <tr key={Math.random()}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {data.busNo}
                                                        </td>

                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {data.isCover ?
                                                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                                    Cover
                                                                </span>
                                                                :
                                                                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                                                    Not Cover
                                                                </span>
                                                            }

                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            {data.isCover ?
                                                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" /> :
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            }
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}