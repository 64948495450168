
import { useState } from 'react';
import History from './History'
import SucessNotification from './SuccessNotification'
import FailNotification from './FailNotification'


const OKINAWA_BUS_ARRAY = [20, 120, 65, 66, 77, 70, 29, 28, 43, 32, 55, 99, 112, 26, 309, 334, 339, 3, 11, 14, 21, 23, 27, 31, 52, 63, 77, 101, 77, 90, 113, 777, 80, 92, 110, 125, 152, 223, 227, 1, 7, 8, 14, 17, 346, 338, 34, 89, 334, 82, 107, 108, 54, 83, 55, 56, 88, 95, 98, 256]


export default function App() {
  const [busNo, setbusNo] = useState("");
  const [history, setHistory] = useState([])
  const [isCover, setIsCover] = useState("")


  const checkIfCover = (e) => {
    e.preventDefault();
    const isCover = OKINAWA_BUS_ARRAY.includes(parseInt(busNo))
    setHistory(oldHistory => [{
      busNo,
      isCover
    }, ...oldHistory])
    setIsCover(isCover)
    setbusNo("")
  }


  return (
    <div className="bg-indigo-200">
      <div className="mx-auto max-w-7xl px-2 py-2 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <div className="overflow-hidden rounded-lg bg-white shadow">

            <form onSubmit={checkIfCover}>
              <div className="border-b border-gray-200 px-4 py-4 sm:px-6 text-center">
                <img src="logo.png" className="text-center mb-4" width="350" height="auto" alt="okinawa-buss-app" />
                <a href="https://www.okinawapass.com" target="_blank" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 ">
                  Okinawa Bus Pass Offcial Site
                </a>
              </div>

              <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                  <div className="space-y-6">
                    <SucessNotification isCover={isCover}/>
                    <FailNotification isCover={isCover}/>
                    <div>
                      <label htmlFor="bus" className="block text-sm font-medium leading-6 text-gray-900">
                        Bus No
                      </label>
                      <div className="mt-2">
                        <input
                          id="bus"
                          name="bus"
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          autoComplete="bus"
                          required
                          value={busNo}
                          onChange={e => setbusNo(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Check
                </button>

              </div>
            </form>
          </div>

        </div>
      </div>
      <History history={history} />
    </div>
  )
}