import { XMarkIcon } from '@heroicons/react/20/solid'

export default function Notification({ isCover }) {
    return (
        <>
            {
                (!isCover && isCover !== "") && <div className="rounded-md bg-red-50 p-2">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XMarkIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-red-800">You pass doesn't cover this bus route.</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
